<template>
    <div class="amher">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first" label="运营列表">
                <!-- 表格内容 -->
                <div class="avatar_table">
                    <el-card>
                        <!-- 添加 删除 下拉框 按钮部分 -->
                        <div class="out_sel_btn">
                            <div style="margin-top: 15px;"></div>
                            <div class="add_del_btn">
                                <el-button type="primary" class="addBtn" @click="operate_add">添加</el-button>
                                <el-button class="delBtn" @click="op_del">删除</el-button>
                            </div>
                        </div>
                        <!-- 内容表格部分 -->
                        <div class="table_init">
                            <el-table ref="operate_multipleTable" :data="operate_tableData" tooltip-effect="dark"
                                style="width: 100%" @selection-change="operate_SelectionChange"
                                :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column prop="uid" label="ID" width="180">
                                </el-table-column>
                                <el-table-column prop="username" label="账号">
                                </el-table-column>
                                <el-table-column prop="phone" label="手机号">
                                </el-table-column>
                                <el-table-column prop="email" label="邮箱">
                                </el-table-column>
                                <el-table-column prop="auth" label="角色权限">
                                </el-table-column>
                                <el-table-column prop="lastlogin" label="上次登录时间">
                                </el-table-column>
                                <el-table-column prop="status" label="状态">
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span style="margin-right:16px" @click="operate_Edit(scope.row.uid)">编辑</span>
                                        <span @click="operate_Delet(scope.row.uid)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <!-- 分页部分 -->
                        <Paging :count="pa_count" :initData="initData" @changePage="handle_changePage"></Paging>
                    </el-card>
                </div>
            </el-tab-pane>
            <el-tab-pane name="second" label="老师列表">
                <!-- 表格内容 -->
                <div class="avatar_table">
                    <el-card>
                        <!-- 添加 删除 下拉框 按钮部分 -->
                        <div class="out_sel_btn">
                            <div class="sel_inp">
                                <el-select v-model="campus_id" placeholder="校区" clearable @change="initData1"
                                    size="mini">
                                    <el-option v-for="item in campus_arr" :key="item.uid" :label="item.name"
                                        :value="item.uid">
                                    </el-option>
                                </el-select>
                                <el-input placeholder="请输入老师名称" v-model="search_inp" clearable @change="initData1"
                                    size="mini">
                                </el-input>
                            </div>
                            <div class="add_del_btn">
                                <el-button type="primary" class="addBtn" @click="teacher_add">添加</el-button>
                                <el-button class="delBtn" @click="te_del">删除</el-button>
                            </div>
                        </div>
                        <!-- 内容表格部分 -->
                        <div class="table_init">
                            <el-table ref="teacher_multipleTable" :data="teacher_tableData" tooltip-effect="dark"
                                style="width: 100%" @selection-change="teacher_SelectionChange"
                                :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column prop="uid" label="ID" width="180">
                                </el-table-column>
                                <el-table-column prop="campus_uid" label="校区名称">
                                </el-table-column>
                                <el-table-column prop="name" label="名称">
                                </el-table-column>
                                <el-table-column prop="phone" label="电话">
                                </el-table-column>
                                <el-table-column prop="status" label="状态">
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span style="margin-right:16px" @click="teacher_Edit(scope.row.uid)">编辑</span>
                                        <span @click="teacher_Delet(scope.row.uid)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <!-- 分页部分 -->
                        <Paging :count="te_count" :initData="initData1" @changePage="te_changePage"></Paging>
                    </el-card>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- 弹框部分 -->
        <!-- 添加编辑弹框     运营*****************************************************************-->
        <el-dialog :visible.sync="operate_dialog" width="828px" :before-close="handleClose" @close="operate_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{title_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="80px">
                <el-form-item label="账号">
                    <el-input v-model="account" placeholder="请输入账号" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="昵称">
                    <el-input v-model="nickname" placeholder="请输入用户昵称" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="phone" placeholder="请输入手机号" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="照片" class="img_load">
                    <div style="display:flex">
                        <el-input style="width:376px;height:30px" placeholder="请上传1张头像" disabled></el-input>
                        <el-upload class="upload-demo" action="https://education.ztxinde.com/auth_user_upload"
                            :headers=myHeaders accept=".png,.gif,.jpg,.jpeg" name="image"
                            :on-success="handleAvatarSuccess" :show-file-list="false">
                            <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">上传
                            </el-button>
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item v-if="img_show">
                    <img style="width:100px;height:100px" :src="img_url" alt="">
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input v-model="mail" placeholder="请输入邮箱地址" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="角色权限">
                    <el-select v-model="role_id" placeholder="请选择角色权限" clearable size="mini">
                        <el-option v-for="item in role_arr" :key="item.uid" :label="item.user_name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input type="password" v-model="password" show-password placeholder="请输入密码(密码限制在6-20位)" clearable
                        size="mini"></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input type="password" v-model="pwd" show-password placeholder="请再次输入密码(密码限制在6-20位)" clearable
                        size="mini">
                    </el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio v-model="radio" label="1">正常</el-radio>
                    <el-radio v-model="radio" label="2">禁用</el-radio>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="handle_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="operate_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 添加编辑弹框     老师*****************************************************************-->
        <el-dialog :visible.sync="teacher_dialog" width="828px" :before-close="handleClose" @close="teacher_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{teacher_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="80px">
                <el-form-item label="校区名称">
                    <el-select v-model="school_id" placeholder="请选择校区" clearable multiple size="mini" ref="selec"
                        @change="handle_sel">
                        <el-option v-for="item in school_name" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="school_teacher" placeholder="请输入名称" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="电话">
                    <el-input v-model="mobile" placeholder="请输入电话" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio v-model="teacher_radio" label="1">任职</el-radio>
                    <el-radio v-model="teacher_radio" label="2">离职</el-radio>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="teacher_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="teacher_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
let token = sessionStorage.getItem("Token");
import Paging from '@/components/Paging';
import { getAmherstApi, showAmherstApi, addAmherstApi, editAmherstApi, delAmherstApi, getTeacherApi, showTeacherApi, addTeacherApi, editTeacherApi, delTeacherApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 选项卡
            activeName: 'first',
            bul_show: true,
            tea_show: false,
            // 登录用户id
            user_id: '',
            // 弹框显示隐藏
            operate_dialog: false,   //添加编辑弹框  运营
            teacher_dialog: false,   //添加编辑弹框  老师
            del_dialog: false,    //删除弹框
            // 删除数据的数组
            del_arr: [],
            // 删除提示语
            del_tip: '',
            // ***************运营*****************
            // 每条数据id  运营
            operate_id: '',
            //  表格数据  运营
            operate_tableData: [],
            operate_multiple: [],
            // 分页
            pa_page: 1,
            pa_count: 0,
            pa_max: 10,
            // token
            myHeaders: {
                'Token': token
            },
            // 图片地址
            img_url: '',
            // 上传图片是否显示
            img_show: false,
            // 弹框内容
            title_name: '',
            btn_name: '',
            account: '',   //账号
            nickname: '',    //昵称
            phone: '',    //手机号
            role_arr: [],   //角色权限
            role_id: '',
            mail: '',     //邮箱
            password: '',   //密码
            pwd: '',     //确认密码
            radio: '1',   //状态
            state_ra: 1,
            // ***************老师****************
            // 每条数据id  老师
            teacher_id: '',
            //  表格数据  老师
            teacher_tableData: [],
            teacher_multiple: [],
            // 分页
            te_page: 1,
            te_count: 0,
            te_max: 10,
            // 搜索输入框
            search_inp: '',
            // 搜索下拉框 校区
            campus_arr: [],
            campus_id: '',
            // 弹框内容
            teacher_name: '',  //弹框名字
            school_name: [],   //校区名字
            school_id: [],
            school_teacher: '',   //老师名称
            mobile: '',    //电话
            teacher_radio: '1',   //状态
            te_radio: 1,
        }
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化  运营
        pa_count () {
            if (this.pa_count == (this.pa_page - 1) * this.pa_max && this.pa_count != 0) {
                this.pa_page -= 1;
                this.initData()
            }
        },
        // 监听分页数量的变化  运营
        te_count () {
            if (this.te_count == (this.te_page - 1) * this.te_max && this.te_count != 0) {
                this.te_page -= 1;
                this.initData1()
            }
        },
    },
    methods: {
        // 选项卡点击
        handleClick () {
            if (this.activeName == 'first') {
                this.initData();
                this.bul_show = true;
                this.tea_show = false;
            } else {
                this.initData1();
                this.bul_show = false;
                this.tea_show = true;
            }
        },
        // ****************运营***********************************************************************
        // 子组件传过来的当前页数   运营
        handle_changePage (data) {
            this.pa_page = data;
        },
        // 初始化数据   运营
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getAmherstApi({
                page: this.pa_page,   //当前页
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.operate_tableData = res.data;
                    this.pa_count = res.count;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法   运营
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.operate_multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.operate_multipleTable.clearSelection();
            }
        },
        operate_SelectionChange (val) {
            this.operate_multiple = val;
        },
        // )点击添加按钮
        operate_add () {
            this.operate_dialog = true;
            this.title_name = '添加';
            this.btn_name = '确认添加';
            showAmherstApi({
                type: 1,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.role_arr = res.auth;  //获取角色权限
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 上传图片成功
        handleAvatarSuccess (res, file, fileList) {
            if (res.status.code == 200) {
                this.$message.success('图片上传成功！');
                this.img_show = true;
                this.img_url = res.status.img;  // 图片地址
            } else {
                this.$message.error('图片上传失败！')
            }
        },
        // 点击修改按钮
        operate_Edit (id) {
            this.operate_dialog = true;
            this.operate_id = id;
            this.title_name = '修改';
            this.btn_name = '确认修改';
            showAmherstApi({
                type: 2,
                id: id,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.role_arr = res.auth;  //获取角色权限
                    this.account = res.data.username;  //账号
                    this.nickname = res.data.nickname;   //昵称
                    this.phone = res.data.phone;  //手机号
                    this.img_url = res.data.avatar;   //图片
                    if (this.img_url == '') {
                        this.img_show = false;
                    } else {
                        this.img_show = true;
                    };
                    this.mail = res.data.email;   //邮箱
                    this.password = res.data.rel_pwd;   //密码
                    this.pwd = res.data.rel_pwd;   //密码
                    this.state_ra = res.data.status;  //状态
                    if (this.state_ra == 1) {
                        this.radio = '1'
                    } else if (this.state_ra == 2) {
                        this.radio = '2'
                    };
                    this.role_id = res.data.auth_id;  //角色权限id
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加修改点击   提交   按钮
        handle_confirm () {
            if (this.radio == '1') {
                this.state_ra = 1;
            } else if (this.radio == '2') {
                this.state_ra = 2;
            };
            if (this.title_name == '添加') {
                addAmherstApi({
                    username: this.account,  //账号
                    nickname: this.nickname,   //昵称
                    phone: this.phone,    //手机号
                    avatar: this.img_url,  // 图片地址
                    email: this.mail,   //邮箱
                    auth_id: this.role_id,   //角色权限id
                    password: this.password,  //密码
                    password_confirmation: this.pwd,  //确认密码
                    status: this.state_ra,   //状态
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.operate_reset();
                        this.operate_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.title_name == '修改') {
                editAmherstApi({
                    id: this.operate_id,
                    username: this.account,  //账号
                    nickname: this.nickname,   //昵称
                    phone: this.phone,    //手机号
                    avatar: this.img_url,  // 图片地址
                    email: this.mail,   //邮箱
                    auth_id: this.role_id,   //角色权限id
                    password: this.password,  //密码
                    password_confirmation: this.pwd,  //确认密码
                    status: this.state_ra,   //状态
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.operate_reset();
                        this.operate_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加修改点击   重置  按钮
        operate_reset () {
            this.account = '';  //账号
            this.phone = '';    // 手机号
            this.role_id = '';   // 角色权限
            this.mail = '';    //邮箱
            this.password = '';    //密码
            this.pwd = '';     //确认密码
            this.radio = '1';   //状态
            this.img_show = false;  //图片显示
            this.img_url = '';
            this.nickname = '';   // 昵称
        },
        // 点击单个删除按钮
        operate_Delet (id) {
            this.operate_id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除
        op_del () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.operate_multiple.length; i++) {
                let del_id = this.operate_multiple[i].uid;
                this.del_arr.push(del_id);
            };
        },

        // **********************老师*******************************************************************
        // 子组件传过来的当前页数
        te_changePage (data) {
            this.te_page = data;
        },
        // 初始化数据   老师
        initData1 () {
            this.user_id = sessionStorage.getItem('id');
            getTeacherApi({
                page: this.te_page,   //当前页
                name: this.search_inp,  //校区名称
                campus_id: this.campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.teacher_tableData = res.data;
                    this.te_count = res.count;
                    this.campus_arr = res.campus;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法   
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.teacher_multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.teacher_multipleTable.clearSelection();
            }
        },
        teacher_SelectionChange (val) {
            this.teacher_multiple = val;
        },
        // )点击添加按钮
        teacher_add () {
            this.teacher_dialog = true;
            this.teacher_name = '添加';
            this.btn_name = '确认添加';
            showTeacherApi({
                type: 1,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school_name = res.campus;   //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 自动隐藏下拉框
        handle_sel () {
            setTimeout(() => {
                this.$refs.selec.blur()
            }, 4000)
        },
        // 点击修改按钮
        teacher_Edit (id) {
            this.teacher_dialog = true;
            this.teacher_name = '修改';
            this.btn_name = '确认修改';
            this.teacher_id = id;
            showTeacherApi({
                id: id,
                type: 2,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school_name = res.campus;   //校区
                    this.school_id = res.data.campus_uid;   //校区id
                    this.school_teacher = res.data.name;    //名称
                    this.mobile = res.data.phone;    //手机号
                    this.te_radio = res.data.status;    //状态
                    if (this.te_radio == 1) {
                        this.teacher_radio = '1'
                    } else {
                        this.teacher_radio = '2'
                    }
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加修改点击   提交   按钮
        teacher_confirm () {
            if (this.teacher_radio == '1') {
                this.te_radio = 1;
            } else if (this.teacher_radio == '2') {
                this.te_radio = 2;
            };
            if (this.teacher_name == '添加') {
                addTeacherApi({
                    campus_uid: this.school_id,//校区id
                    name: this.school_teacher,  //名称老师
                    phone: this.mobile, //手机号
                    status: this.te_radio,  // 状态
                    auth_userid: this.user_id, //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData1();
                        this.teacher_reset();
                        this.teacher_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.teacher_name == '修改') {
                editTeacherApi({
                    id: this.teacher_id,
                    campus_uid: this.school_id,//校区id
                    name: this.school_teacher,  //名称老师
                    phone: this.mobile, //手机号
                    status: this.te_radio,  // 状态
                    auth_userid: this.user_id, //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData1();
                        this.teacher_reset();
                        this.teacher_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加修改点击   重置  按钮
        teacher_reset () {
            this.school_id = [];   //校区名字
            this.school_teacher = '';   //老师名称
            this.mobile = '';   //电话
            this.teacher_radio = '1';  //状态
        },
        // 点击单个删除按钮   老师
        teacher_Delet (id) {
            this.teacher_id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除  老师
        te_del () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.teacher_multiple.length; i++) {
                let del_id = this.teacher_multiple[i].uid;
                this.del_arr.push(del_id);
            };
        },
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除弹框点击  提交 按钮
        handle_del () {
            if (this.activeName == 'first') {
                delAmherstApi({
                    id: this.del_arr,
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('删除成功');
                        this.del_dialog = false;
                        this.initData();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else {
                delTeacherApi({
                    id: this.del_arr,
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('删除成功');
                        this.del_dialog = false;
                        this.initData1();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 弹窗关闭
        handleClose () {
            this.operate_dialog = false;
            this.teacher_dialog = false;
            this.del_dialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.amher {
    width: 100%;
    height: 100%;
    // 选项卡
    .el-tabs {
        ::v-deep.el-tabs__active-bar {
            display: none;
        }
        ::v-deep.el-tabs__item {
            width: 112px;
            height: 40px;
            text-align: center;
            color: #525252;
            font-size: 18px;
            font-weight: 900;
        }
        // ::v-deep.el-tabs__item:hover {
        //     color: #333333;
        //     font-size: 18px;
        //     font-weight: 900;
        // }
        ::v-deep.el-tabs__item.is-active {
            width: 112px;
            height: 40px;
            // text-align: center;
            border-radius: 24px;
            background: #2479dd;
            color: #ffffff;
            font-weight: 500;
        }
        ::v-deep#tab-first {
            text-align: right;
        }
        ::v-deep#tab-second {
            text-align: left;
        }
        ::v-deep.el-tabs__nav-wrap::after {
            position: static !important;
        }
        // 表格显示内容
        .avatar_table {
            margin-bottom: 20px;
            // 下拉框  添加  删除按钮部分
            .out_sel_btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // 下拉框部分
                .sel_inp {
                    margin-top: 15px;
                    display: flex;
                    .el-select {
                        width: 140px;
                        height: 30px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        ::v-deep.el-input__inner {
                            width: 140px;
                            height: 30px;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                            ::v-deep.el-select__caret {
                                line-height: 30px;
                            }
                            ::v-deep.el-input__icon {
                                line-height: 30px;
                            }
                        }
                    }
                    .el-input {
                        width: 155px;
                        height: 30px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-left: 0px;
                        ::v-deep.el-input__inner {
                            width: 155px;
                            height: 30px;
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-left: 0px;
                        }
                    }
                }
                // 添加  删除按钮部分
                .add_del_btn {
                    text-align: right;
                    .el-button {
                        width: 80px;
                        height: 30px;
                        line-height: 4px;
                    }
                    .addBtn {
                        margin-right: 8px;
                    }
                }
            }
            // 表格内容
            .table_init {
                margin-top: 20px;
                text-align: center;
                // 操作按钮
                span {
                    border: none;
                    width: 24px;
                    height: 18px;
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                }
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            display: flex;
            align-items: center;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            .sd {
                width: 18px;
                height: 4px;
                margin-right: 8px;
                background: #2479dd;
            }
            .ax {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
            //照片上传
            .img_load {
                ::v-deep.el-input__inner {
                    width: 376px;
                    height: 30px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>